import './App.css';
import { useState, useEffect } from 'react';

function App() {
    const [bgImage, setBgImage] = useState(
        window.innerWidth < 800 ? "url('mobilebackground.jpg')" : "url('pcbackground.jpg')"
    );

    const handleResize = () => {
        setBgImage(window.innerWidth < 800 ? "url('mobilebackground.jpg')" : "url('pcbackground.jpg')");
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className="navbar bg-base-100 fixed">
                <div className="flex-1">
                    <a className="btn btn-ghost normal-case text-xl" href="/#">莲子粥🌸</a>
                </div>
                <div className="flex-none mx-2">
                    <ul className="menu menu-horizontal px-1">
                        <li><a rel="noreferrer" target="_blank" href="https://github.com/LianZiZhou">GitHub</a></li>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.com/LianZhou">GitLab</a></li>
                        <li>
                            <details>
                                <summary>
                                    Social
                                </summary>
                                <ul className="p-2 bg-base-100">
                                    <li><button onClick={() => {window.open('mailto:lianzhouNoRobotHeremtf.is'.replace('NoRobotHere', '@'))}}>Email</button></li>
                                    <li><a rel="noreferrer" target="_blank" href="https://lianzhou.t.me">Telegram</a></li>
                                </ul>
                            </details>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="hero min-h-screen bg-base-200" style={{backgroundImage: `linear-gradient(rgba(229,231,235,0.75), rgba(229,231,235,0.75)), ${bgImage}`}}>
                <div className="hero-content text-center">
                    <div className="max-w-md">
                        <div className="py-6">
                            <div className="avatar online">
                                <div className="w-24 rounded-full">
                                    <img src="/profile.jpg" alt="profile" />
                                </div>
                            </div>
                        </div>
                        <h1 className="text-5xl font-bold">你好!👋</h1>
                        <p className="py-6">这里是莲子粥!🌸<br />一名每天都要坚持熬粥的偶像!🍥<br />话说咱可是要闪闪拯救世界呐!✨<br /><br />Full Stack Web / NodeJS / Serverless</p>
                        <button onClick={() => {window.open('https://home.lzz.moe')}} className="btn btn-primary">咱的小窝🏠</button><button onClick={() => {window.open('https://t.me/lian_zi_zhou_daily')}} className="btn btn-primary mx-2">粉丝早餐报🍥</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
